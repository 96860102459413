import React from "react";
import { contactPageData } from "../../portfolio";
import Jeremiah from "../../assets/images/jeremy.png";

export default function Jeremy() {
    return(
        <div>
            <img src={Jeremiah} alt="Jeremy Profile Pic"></img>
            <h1>Jeremy Marchesani</h1>
            <h4>Software Engineer</h4>
            <p>{contactPageData.emails.jeremy}</p>
        </div>
    )
}