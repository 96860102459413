import React, { Component } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import HomePage from "../pages/home/HomePage";
import ContactUs from "../pages/contact_us/ContactUs";
import WhatIsPrayed4 from "../pages/what_is_prayed4/WhatIsPrayed4";
import AboutUs from "../pages/about_us/AboutUs";
import UserStories from "../pages/user_stories/UserStories";

export default class Main extends Component {
    render() {
        return(
            <div>
                <HashRouter basename="/">
                    <Switch>
                    <Route
                        path="/"
                        exact
                        render={(props) => <HomePage />}
                    />
                    <Route
                        path="/home"
                        render={(props) => <HomePage />}
                    />
                    <Route
                        path="/what-is-prayed4"
                        exact
                        render={(props) => (
                        <WhatIsPrayed4 />
                        )}
                    />
                    <Route
                        path="/user-stories"
                        render={(props) => (
                        <UserStories />
                        )}
                    />
                    <Route
                        path="/about-us"
                        render={(props) => (
                        <AboutUs />
                        )}
                    />
                    <Route
                        path="/contact-us"
                        render={(props) => (
                        <ContactUs />
                        )}
                    />
                    </Switch>
                </HashRouter>
            </div>
        );
    }
}