

const greeting = {
    title: "Prayed4",
    logo_name: "Prayed4Logo",
    vision_statement: "A world grounded in prayer and uplifted by faith.",
    mission_statement: "To connect people in prayer through exceptional and inspirational products, services, and technology.",
  };

// Contact Page
const contactPageData = {
    emails: {
      support: "support@prayed4.app",
      mike: "michael.feldmann@prayed4.app",
      jeremy: "jeremy.marchesani@prayed4.app",
      brady: "brady.north@prayed4.app",
    }
  };

const legal = {
  privacy_policy: "https://firebasestorage.googleapis.com/v0/b/prayed4-website.appspot.com/o/Prayed4%20Mobile%20App%20Privacy%20Policy.pdf?alt=media&token=ea446c35-8257-4d18-a3bf-168c07794ed3",
  terms_of_service: "https://firebasestorage.googleapis.com/v0/b/prayed4-website.appspot.com/o/Prayed4%20Mobile%20App%20Terms%20and%20Conditions.pdf?alt=media&token=4e65195f-3193-410e-ac75-5d9410ddf423",
}

export {
    greeting,
    contactPageData,
    legal,
}