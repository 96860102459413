import React, { Component } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import Jeremy from "../../containers/about_us/Jeremy";
import Michael from "../../containers/about_us/Michael";
import Brady from "../../containers/about_us/Brady";

class AboutUs extends Component {
  render() {
    return (
        <div className="App">
            <Header />
            <header className="App-header">
                <Michael/>
                <br/><br/>
                <Jeremy/>
                <br/><br/>
                <Brady/>
            </header>
            <Footer/>
        </div>
    );
  }
}

export default AboutUs;
