import React, { Component } from "react";
import Header from "../../components/header/Header";
import "./ContactUs.css";
import { contactPageData } from "../../portfolio";
// import ContactForm from "../../components/forms/ContactForm";
import Footer from "../../components/footer/Footer";

class ContactUs extends Component {
  render() {
    return (
      <div className="contact-main">
        <Header />
        <div className="schedule-form">
          {/* <div className="schedule-text-div">
            <p>Schedule a meeting with us</p>
          </div> */}
          <div className="schedule-form-div">
            {/* <ContactForm /> */}
          </div>
        </div>
        <div className="support-email">
          <p>Contact our team at: </p>
          <p>{contactPageData.emails.support}</p>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default ContactUs;
