import React from "react";
import { contactPageData } from "../../portfolio";
import BNorth from "../../assets/images/brady.png";

export default function Brady() {
    return(
        <div>
            <img src={BNorth} alt="Brady Profile Pic"></img>
            <h1>Brady North</h1>
            <h4>Business Development Manager</h4>
            <p>{contactPageData.emails.brady}</p>
        </div>
    )
}