import React from "react";
import "./ThirdThree.css";
import Announcements from "../../../assets/images/announcements.png";
import GroupRequests from "../../../assets/images/group_requests.png";
import AddRequest from "../../../assets/images/add_request.png";

export default function HomeFeatures(props) {
  return (
    <div className="third-three">
        <span>
            <img src={GroupRequests} alt="Groups Requests"></img>
        </span>
        <span>
            <img src={Announcements} alt="Group Announcements"></img>
        </span>
        <span>
        <img src={AddRequest} alt="Add Request"></img>
        </span>
    </div>
  );
}