import React from "react";
import "./HomeFeatures.css";
import WelcomePage from "../../assets/images/welcome_page.png";
import GroupRequests from "../../assets/images/group_requests.png";
import AddRequest from "../../assets/images/add_request.png";

export default function HomeFeatures(props) {
  return (
    <div className="features-main">
        <span>
            <img src={WelcomePage} alt="Welcome page"></img>
        </span>
        <span>
            <img src={GroupRequests} alt="Group Requests"></img>
        </span>
        <span>
            <img src={AddRequest} alt="Add Request"></img>
        </span>
    </div>
  );
}