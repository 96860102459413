import React from "react";
import "./FourthThree.css";
import PrayerGroupDropdown from "../../../assets/images/prayer_group_dropdown.png";
import PrayerRequests from "../../../assets/images/prayer_requests.png";
import SpecificPrayer from "../../../assets/images/specific_prayer.png";

export default function HomeFeatures(props) {
  return (
    <div className="fourth-three">
        <span>
            <img src={PrayerGroupDropdown} alt="Prayer Group Dropdown"></img>
        </span>
        <span>
            <img src={PrayerRequests} alt="Prayer Requests"></img>
        </span>
        <span>
            <img src={SpecificPrayer} alt="Specific Prayer"></img>
        </span>
    </div>
  );
}