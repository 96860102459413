import React from "react";
import "./Greeting.css";
import { greeting } from "../../portfolio";
import Logo from "../../assets/images/logo.png"

export default function Greeting(props) {
  return (
    <div className="greeting-main">
      <div className="greeting-text-div">
        <div>
          <h2 className="greeting-text" >
          { greeting.vision_statement }
          <br/><br/><br/>
          { greeting.mission_statement }
          </h2>
        </div>
      </div>
      <div className="greeting-image-div">
        <img src={Logo} alt="Prayed4 Logo"></img>
      </div>
    </div>
  );
}
