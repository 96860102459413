import React, { Component } from "react";
import "./HomePage.css"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import HomeFeatures from "../../containers/features/HomeFeatures";
import Greeting from "../../containers/greeting/Greeting";

class Home extends Component {
  render() {
    return (
        <div className="app">
            <Header />
            <Greeting />
            <HomeFeatures />
            <div className="links">
              <a href='https://apps.apple.com/us/app/prayed4/id1618811541'>Download Prayed4 on the App Store</a>
              <a href='https://play.google.com/store/apps/details?id=com.example.prayer_app'>Download Prayed4 on Google Play</a>
            </div>
            <br/>
            <Footer/>
        </div>
    );
  }
}

export default Home;
