import React from "react";
import { contactPageData } from "../../portfolio";
import Mike from "../../assets/images/mike.png";

export default function Micahel() {
    return(
        <div>
            <img src={Mike} alt="Michael Profile Pic"></img>
            <h1>Michael Feldmann</h1>
            <h4>Lead Software Engineer</h4>
            <p>{contactPageData.emails.mike}</p>
        </div>
    )
}