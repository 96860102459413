import React, { Component } from "react";
import Header from "../../components/header/Header";
import FirstThree from "../../containers/features/what_is_prayed4/FirstThree";
import SecondThree from "../../containers/features/what_is_prayed4/SecondThree";
import ThirdThree from "../../containers/features/what_is_prayed4/ThirdThree";
import FourthThree from "../../containers/features/what_is_prayed4/FourthThree";
import Footer from "../../components/footer/Footer";

class WhatIsPrayed4 extends Component {
  render() {
    return (
        <div className="What-Is-Prayed4">
            <Header />
            <header className="basic-info">
              <ul>
                  <li>Let others know what you'd like them to pray for.</li>
                  <li>Build your community by exploring people/groups you want to connect with.</li>
                  <li>You will be notified when someone has prayed for you, and others will be notified when you have prayed for them.</li>
              </ul>
            </header>
            <FirstThree />
            <SecondThree />
            <ThirdThree />
            <FourthThree />
            <Footer/>
        </div>
    );
  }
}

export default WhatIsPrayed4;
