import React, { Component } from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

const onMouseEnter = (event, color) => {
  const el = event.target;
  el.style.backgroundColor = color;
};

const onMouseOut = (event) => {
  const el = event.target;
  el.style.backgroundColor = "transparent";
};

class Header extends Component {
  render() {
    // const theme = this.props.theme;
    // const link = settings.isSplash ? "/splash" : "home";
    return (
      <div>
        <header className="header">
          <NavLink 
            to={"home"} 
            tag={Link}
            className="logo"
            style={{ color: "white" }}
          >
            <img src={Logo} alt="Prayed4 Logo"/>
          </NavLink>
          <ul className="menu" >
            <li>
              <NavLink
                to="/home"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: "white" }}
                onMouseEnter={(event) => onMouseEnter(event)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/what-is-prayed4"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: "white" }}
                onMouseEnter={(event) => onMouseEnter(event)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                What is Prayed4?
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                to="/user-stories"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: "white" }}
                onMouseEnter={(event) => onMouseEnter(event)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                User Stories
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/about-us"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: "white" }}
                onMouseEnter={(event) => onMouseEnter(event)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact-us"
                tag={Link}
                activeStyle={{ fontWeight: "bold" }}
                style={{ color: "white" }}
                onMouseEnter={(event) => onMouseEnter(event)}
                onMouseOut={(event) => onMouseOut(event)}
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </header>
      </div>
    );
  }
}
export default Header;
