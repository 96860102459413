import React, { Component } from "react";
import Header from "../../components/header/Header";

class UserStories extends Component {
  render() {
    return (
        <div className="App">
            <Header />
            <header className="App-header">
                <h1>User Stories</h1>
            </header>
        </div>
    );
  }
}

export default UserStories;
