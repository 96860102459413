import React from 'react';
import "./FirstThree.css";
import WelcomePage from "../../../assets/images/welcome_page.png";
import UserSearch from "../../../assets/images/user_search.png";
import AllFriends from "../../../assets/images/all_friends.png";

export default function FirstThree(props) {
    return (
        <div className="first-three">
            <span>
                <img src={WelcomePage} alt="Welcome Page"></img>
            </span>
            <span>
                <img src={UserSearch} alt="Group Search Page"></img>
            </span>
            <span>
                <img src={AllFriends} alt="All Friends Page"></img>
            </span>
        </div>
    );
  }