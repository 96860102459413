import React, { Component } from "react";
import "./Footer.css";
import { legal } from "../../portfolio";

class Footer extends Component {
    render() {
        return(
            <div>
                <footer className="footer">
                    <ul className="legal" >
                        <li>
                            <a href={ legal.privacy_policy }>Privacy Policy</a>
                        </li>
                        <li>
                            <a href={ legal.terms_of_service }>Terms Of Service</a>
                        </li>
                    </ul>
                </footer>
            </div>
        );
    }
}
export default Footer;