import React from "react";
import "./SecondThree.css";
import UserPage from "../../../assets/images/user_page.png";
import SendPrayer from "../../../assets/images/send_prayer.png";
import AllGroups from "../../../assets/images/all_groups.png";

export default function HomeFeatures(props) {
  return (
    <div className="second-three">
        <span>
            <img src={UserPage} alt="User Page"></img>
        </span>
        <span>
            <img src={SendPrayer} alt="Send Prayer"></img>
        </span>
        <span>
            <img src={AllGroups} alt="All Groups Page"></img>
        </span>
    </div>
  );
}